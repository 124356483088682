.SidebarTitle {
    background-color: var(--grey-color);
    padding: 20px 20px 18px;
    font-weight: 700;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.SidebarTitle > svg {
    margin-right: 10px;
    cursor: pointer;
    width: 20px;
}

.Directions {
    height: calc(100% - 50px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.DirectionsInner {
    position: relative;
    padding: 20px;
    display: flex;
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    height: 150px;
    box-sizing: border-box;
    z-index: 1;
}

.DirectionsIcons {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DirectionsInputs {
    flex: 1;
}
.DirectionsInputs input {
    margin-bottom: 15px;
}

.StartingPoint {
    width: 14px;
    height: 14px;
    border: solid 1px;
    border-radius: 50%;
    margin-top: 15px;
    margin-bottom: 7px;
}

.EndingPoint svg {
    width: 20px;
    color: var(--danger-color);
}

.SelectStartingPoint {
    display: none;
    position: absolute;
    top: 150px;
    left: 0;
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    width: 100%;
    z-index: 100000000;
    background: #fff;
    height: calc(100% - 150px);
    overflow: auto;
}
.SelectStartingPoint.Show {
    display: block;
}

.PathResults {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
}

.Location {
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease-out;
    border-bottom: solid 1px var(--border-color);
}
.Location:hover {
    background-color: var(--neutral-color);
}

.NoResults {
    padding: 15px 20px;
    box-sizing: border-box;
    display: block;
}

.ResultsWrapper {
    overflow: auto;
}

.LocationIQ {
    text-align: right;
    padding: 6px 20px;
}
.LocationIQ a {
    font-size: var(--small-font-size);
    text-decoration: none;
}

:local(.PathResults) :global(.leaflet-routing-container) {
    box-shadow: none;
}
:local(.PathResults) :global(.leaflet-routing-alt) {
    max-height: unset !important;
}
:local(.PathResults) :global(.leaflet-routing-collapse-btn) {
    display: none;
}

:local(.PathResults) :global(.leaflet-routing-alt > h2) {
    display: none;
}
:local(.PathResults) :global(.leaflet-routing-alt > h3) {
    margin-bottom: 20px;
    font-size: var(--normal-font-size);
}

.BusinessSearchItem {
    padding: 5px 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    background-color: #fff;
    transition: background-color 0.3s ease-out;
}
.BusinessSearchItem svg {
    margin-right: 15px;
    width: 18px;
    display: inline-block;
    margin-bottom: -6px;
}

.BusinessSearchItem:hover {
    background-color: var(--neutral-color);
}
