.TitleWrapper {
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.TitleWrapper svg {
    fill: rgba(136, 142, 168, 0.1);
    margin-right: 20px;
}

.Title h2 {
    margin: 0;
    font-weight: 700;
}

.Title {
    display: flex;
    flex-direction: column;
}
.Title > span {
    color: var(--font-color-secondary);
}
