.ActivationWrapper {
    height: 100%;
    padding: 60px 10%;
    box-sizing: border-box;
}
.ActivationWrapper h3 {
    margin: 20px 0;
}

.ErrorActivation,
.SuccessActivation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.IconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.ErrorActivation .IconWrapper {
    background: var(--danger-color);
}
.SuccessActivation .IconWrapper {
    background: var(--success-color);
}

.ErrorActivation .IconWrapper svg {
    width: 80px;
    height: 80px;
    color: #fff;
}
.SuccessActivation .IconWrapper svg {
    width: 50px;
    height: 50px;
    color: #fff;
}
