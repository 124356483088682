.GooglePlaces {
    width: 100%;
    margin-bottom: 15px;
}

.GooglePlaces input {
    width: 100%;
    border: 1px solid #bfc9d4;
    color: #3b3f5c;
    padding: 8px 10px;
    padding: 1rem 1.25rem;
    border-radius: 6px;
    height: auto;
    transition: none;
    box-sizing: border-box;
    display: block;
    appearance: none;
    outline: none;
    background-color: var(--background-color);
}
.GooglePlaces input:focus-visible {
    box-shadow: none;
    border-color: var(--action-color);
    color: #3b3f5c;
    background-color: var(--background-color);
}

:global(.pac-container) {
    /* border-radius: 6px; */
    /* border: 1px solid #bfc9d4; */
    /* margin-top: -1px; */
    /* padding: 5px 0; */
    box-shadow: none;
    width: 100% !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    display: block !important;
    border: 0 !important;
    /* box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%); */
}
:global(.pac-container)::after {
    display: none;
}

:global(.pac-item) {
    border-top: solid 1px var(--border-color);
    padding: 5px 20px;
    border-top: none;
    cursor: pointer;
}

:global(.pac-icon) {
    margin-top: 5px;
    margin-right: 5px;
}
