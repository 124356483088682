.TopbarWrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e0e6ed;
    box-shadow: 18px 20px 10.3px -23px rgb(0 0 0 / 15%);
}

.Topbar {
    position: relative;
    padding: 0 25px;
    height: 100px;
    width: 100%;
    /* max-width: 1400px; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 1600px) {
    .Topbar {
        width: 1400px;
        padding: 0 50px;
    }
}
@media (min-width: 700px) {
    .Topbar {
        padding: 0 50px;
    }
}

.Avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: var(--action-color);
    color: #fff;
    font-weight: 700;
    cursor: pointer;
}

/* ----------- */
.Header {
    width: 100%;
    display: flex;
    align-items: center;
}

.HeaderNav {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.MoreIconWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* .MoreIcon {
    cursor: pointer;
} */

.DropdownMenu {
    position: absolute;
    top: 25px;
    right: 0px;
    padding: 0px 14px 10px 14px;
    z-index: 9999;
    box-shadow: 0 10px 30px 0 rgb(31 45 61 / 10%);
    background: #fff;
    border: 1px solid #ebedf2;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    display: block;
    width: 135px;
    transition: top 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;

    color: #212529;
}
.DropdownMenu.Show {
    opacity: 1;
    visibility: visible;
    top: 50px;
}

.UserProfile {
    padding: 16px 28px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-right: -14px;
    margin-left: -14px;
    margin-top: -1px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e6ed;
}
.UserProfile h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 3px;
    color: #000;
}
.UserProfile span {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--action-color);
}

.UserLink {
    display: block;
    color: #515365;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.UserLink svg {
    width: 18px;
    margin-right: 13px;
    height: 18px;
}

.UserLink:hover {
    color: var(--action-color);
    background: #ebedf2;
}
.UserLink:hover span {
    color: var(--action-color);
}

.MobileMenuButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.MobileMenuButton svg {
    color: #0e1726;
}
