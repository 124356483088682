/* Popup */
.PopupImage {
    height: 120px;
    background-color: #e9e9e9;
}

.PopupInfo {
    padding: 20px;
    background-color: var(--grey-color);
}

.PopupInfo > h4 {
    margin: 0;
}

.BusinessType {
    color: #fff;
    opacity: 0.9;
    font-size: var(--small-font-size);
}

.BusinessDetails {
    margin-top: 20px;
}

.BusinessDetail {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
}
.BusinessDetail > span {
    font-size: var(--small-font-size);
}

.BusinessDetail svg {
    color: #fff;
    opacity: 0.9;
    flex: 0 0 20px;
}

.PopupDescription {
    padding: 20px;
    color: var(--font-color-secondary);
    font-size: var(--small-font-size);
    display: block;
    max-height: 200px;
    overflow: auto;
}

.PopupLinks {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    border-top: solid 1px var(--border-color);
}

.PopupLink,
.PopupLinkWithFill {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
}

.PopupLink.Disabled,
.PopupLinkWithFill.Disabled {
    display: none;
}

.PopupLink > span,
.PopupLinkWithFill > span {
    font-size: var(--small-font-size);
    color: var(--success-color);
}
.PopupLink > svg {
    height: 25px;
    color: var(--success-color);
}
.PopupLinkWithFill > svg {
    height: 25px;
    fill: var(--success-color);
}
.PopupLinks .Directions {
    margin-left: auto;
}
