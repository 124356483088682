.BusinessRow {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background-color: #fff;
    border-bottom: solid 1px var(--border-color);
    transition: background-color 0.3s ease-out;
    user-select: none;
}

.BusinessRow:hover {
    background-color: var(--neutral-color);
}

.TitleWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.TitleWrapper > span:first-child {
    font-weight: 700;
    margin-bottom: 2px;
}
.TitleWrapper > span:last-child {
    font-size: var(--small-font-size);
    color: var(--font-color-secondary);
}

.Website,
.Directions {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.Website svg,
.Directions svg {
    height: 25px;
    fill: var(--success-color);
    margin-bottom: 3px;
}
.Website span,
.Directions span {
    font-size: var(--small-font-size);
    color: var(--success-color);
}

/* .Website {
    margin-left: auto;
    margin-right: 10px;
} */

.Website.Disabled {
    display: none;
}

.Description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
}

.Col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
