.PdfViewer {
    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgb(22, 28, 36) 100%);
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
}
.PdfViewer > div:nth-child(2) {
    z-index: 2;
    position: fixed;
    width: 100%;
    height: 100%;
}

.CloseButton {
    position: absolute;
    right: 10px;
    z-index: 100;
    font-weight: 700;
    cursor: pointer;
}

.PdfViewer footer > button:nth-child(1) {
    width: 15% !important;
}
.PdfViewer footer > button:nth-child(2) {
    width: 15% !important;
    left: 15% !important;
}
.PdfViewer footer > input {
    width: 25% !important;
    left: 30% !important;
}
.PdfViewer footer > button:nth-child(4) {
    width: 15% !important;
    left: 55% !important;
}
.PdfViewer footer > button:nth-child(5) {
    width: 15% !important;
    left: 70% !important;
}
.PdfViewer footer > button:nth-child(6) {
    position: absolute;
    width: 15%;
    height: 100%;
    left: 85%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAACR0lEQVQ4y42Uy0vUURTHvzo+qokCY1B7DtIoKmUtqrFMEJIeWoSEUVLLtkH0WAh16w+IhKIk0UWUFNEDLPIBBtGmIKRFDNnC8hFoyczIPH7+nPm0GB1ndAI5q3P5fs6959xzjowWjWyO0M4QQSDAEPepJztVYZQqP4GPOJ+5SQunaOEWH4jxjcYMAPl0EecJ29Pj4eEpcR6SlwaQRx9+jqWLk1AjQXoXkMRRF5NUZJYbGVHF1FxnEqCJGHWpAuu29cy6o5R0qSf+u9nISOQwTHt6RMtvEQ2oKO2WjrnhyrVG4jg2W5cDkaDSCoCb2JczRqKTvqVvzgQYMTj9WE7hozUTEA0sS/265VORsDk/L2uzpuctbmHFk95dOYyMOB2Pyi3g6DzQY5HJJgdVaGREA8gjbE4aGSn774NwIBQMBSP+xA0JLxT0PdcWIyOa41GViu9cMzJSrlzaIa+8cll+i/CMauSVV3tUrgIjI1ojP+QRj3i7wir1j7+SWzRhUbyCf9iM/fqiCkUuI7QtB8Iz8qQB9yJjrl1aLeXNnsOmOqXjc6a6xj76XqYC1DDX0yq3siSHnPYLRhcnQbnaoFKVJFI1MqKUybF+7ZTTSEbKu1BsfWKK2v80dx1/pr9WHJQrOQ9adXabvxeb9qXps5EO7F+D5bUqVlbKiCrfWfj+UvQnNgNcoYFDNHCZN8yGRrtvaLdcCXnKEpBD68vK3l0d741MEANi4YmRgW5TsF8lWpNxaxjJoXVyq0r7qg/rgPaqUpvkXIidsH93GgPxLHsoywAAAABJRU5ErkJggg==);
    background-size: 2.4rem;
    transform: rotate(45deg);
}

:local(.PdfViewer) :global(#viewer) {
    overflow-y: hidden !important;
}
