.Navigation {
    display: flex;
    align-items: center;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.Navigation li {
    margin-right: 20px;
    white-space: nowrap;
}
.Navigation li:last-child {
    margin-right: 0;
}

.NavigationItem a {
    position: relative;
    text-decoration: none;
    transition: color 0.3s ease-out;
}

.NavigationItem li a:hover span {
    color: var(--action-color);
}

.NavigationItem a:active span,
.NavigationItem a.active span {
    color: var(--action-color);
}

.Navigation.IsMobile {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
}
.Navigation.IsMobile li {
    margin: 0;
    padding: 5px 0;
}
