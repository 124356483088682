.SidebarTitle {
    background-color: var(--grey-color);
    padding: 20px 20px 18px;
    font-weight: 700;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.SidebarTitle > svg {
    margin-right: 10px;
    cursor: pointer;
    width: 20px;
}

.Directions {
    height: calc(100% - 50px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.PathResults {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
}

:local(.PathResults) :global(.leaflet-routing-container) {
    box-shadow: none;
}
:local(.PathResults) :global(.leaflet-routing-alt) {
    max-height: unset !important;
}
:local(.PathResults) :global(.leaflet-routing-collapse-btn) {
    display: none;
}

:local(.PathResults) :global(.leaflet-routing-alt > h2) {
    display: none;
}

:local(.PathResults) :global(.leaflet-routing-alt > h3) {
    margin-bottom: 20px;
    font-size: var(--normal-font-size);
}
