.Widget {
    position: relative;
    padding: 25px;
    background: #fff;
    position: relative;
    border-radius: 6px;
    border: 1px solid #e0e6ed;
    box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
}

.NoPadding.Widget {
    padding: 0;
}

.Curtain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: saturate(200%) blur(6px);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
}
.Curtain > span {
    color: var(--font-color-heading);
    font-size: var(--mid-font-size);
    font-weight: 600;
    user-select: none;
}
.Curtain.Show {
    opacity: 1;
    pointer-events: all;
}

.AlertMessage {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid rgba(226, 160, 63, 0.55);
    border-right: 64px solid rgba(226, 160, 63, 0.55);
    color: #515365;
    background-color: #fcf5e9;
    border-radius: 6px;
    padding: 17px;
    width: 100%;
}
.AlertMessage::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    margin-top: -8px;
    left: auto;
    right: 0;
    border-left: 0;
    border-right: 8px solid;
    border-right-color: inherit;
}
.AlertMessage > svg {
    color: #fff;
    width: 25px;
    right: -49px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    -webkit-font-smoothing: antialiased;
    margin-right: 5px;
}
.AlertMessage > span {
    width: 100%;
}
.AlertMessage > span > strong {
    margin-right: 10px;
    color: #e2a03f;
}

/* Dark */
body[data-theme='dark'] .Widget {
    background: #0e1726;
    border-color: #0e1726;
}

body[data-theme='dark'] .Curtain {
    background: rgba(14, 23, 38, 0.7);
}

body[data-theme='dark'] .AlertMessage {
    background-color: #282625;
    border-color: rgba(226, 160, 63, 0.55);
    color: #e2a03f;
}

body[data-theme='dark'] .AlertMessage > span {
    color: #e0e6ed;
}
