.CoursesListTitle {
    padding: 23px 25px 20px;
    border-bottom: solid 1px var(--border-color);
    font-weight: 700;
}

.Widgets > div > div {
    height: 100%;
    box-sizing: border-box;
}
.Widgets {
    display: grid;
    align-items: flex-start;
    gap: 20px;
}

.Widgets .Col25 {
    overflow: hidden;
}

.Widgets .Col25 > div {
    display: flex;
    flex-direction: column;
}

.TitleRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0;
}

.TitleRow > div {
    margin: 10px 0;
}

.TitleRow .NotesWrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    cursor: pointer;
}
.TitleRow .NotesWrapper > svg {
    width: 18px;
    height: 18px;
    color: var(--font-color);
    margin-top: -2px;
}

@media (min-width: 1000px) {
    .Widgets {
        grid-template-columns: repeat(3, 1fr);
    }
    .Widgets .Col12 {
        grid-column-start: 1;
        grid-column-end: 2;
    }
    .Widgets .Col25 {
        grid-column-start: 2;
        grid-column-end: 5;
    }
}

@media (max-width: 1000px) {
    .Col12 .CoursesWrapper {
        position: absolute;
        top: 45px;
        left: -1px;
        width: 100%;
        height: 500px;
        background: #fff;
        z-index: 1;
        border-radius: 0 0 6px 6px;
        border: 1px solid #e0e6ed;
        border-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: top 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
    }

    .Col12.ShowCoursesList .CoursesWrapper {
        opacity: 1;
        visibility: visible;
        top: 65px;
        max-height: 468px;
        overflow: auto;
        z-index: 11;
    }

    .CoursesListTitle {
        border-bottom: none;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }
    .CoursesListTitle:hover {
        background-color: #d9e3ef;
    }
}

.WidgetsCol {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.CertificationSection {
    padding: 25px;
    box-sizing: border-box;
    background-color: var(--grey-color);
}
.CertificationSection > h4 {
    margin: 0;
}
.CertificationSection > span {
    font-size: var(--small-font-size);
    line-height: 1.3;
    display: block;
    margin-bottom: 10px;
}

/* Tabs */
.Tabs {
    display: flex;
    align-items: center;
    border-bottom: solid 1px var(--border-color);
    flex-wrap: wrap;
}
.Tab {
    position: relative;
    padding: 18px;
    cursor: pointer;
}
.Tab.TabSelected {
    font-weight: 700;
}
.Tab.TabSelected::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--font-color-heading);
    bottom: -1px;
    left: 0;
}

.TabContent {
    padding: 18px;
}

/* Viewer tabs */
.ViewerTabs {
    display: flex;
    align-items: center;
}
.ViewerTabs > div {
    flex: 1;
    text-align: center;
    padding: 23px 10px 20px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #fff;
    border-right: solid 1px var(--border-color);
    transition: background-color 0.3s ease-out;
}

.ViewerTabs > div:first-child {
    border-radius: 6px 0 0 0;
}
.ViewerTabs > div:last-child {
    border-right: none;
    border-radius: 0 6px 0 0;
}

.ViewerTabs > div.Active {
    background-color: var(--action-color);
    color: #fff;
    font-weight: 700;
}

.ViewerTabsContent {
    flex: 1;
}

/* Assessment */
.Flex1 {
    flex: 1;
}
.Flex1 > div {
    height: 100%;
    box-sizing: border-box;
}

.AssessmentTitle {
    margin-bottom: 5px;
    font-weight: 700;
}

.TestResultWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.TestUser > h3 {
    font-size: var(--mid-font-size);
    font-weight: 700;
}
.TestUser > span {
    font-size: var(--mid-font-size);
    font-weight: 700;
}

.SuccessCircle,
.FailureCircle {
    margin-bottom: 50px;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    color: #fff;
    font-weight: 600;
    margin-top: 50px;
}
.SuccessCircle {
    background: var(--success-color);
}
.FailureCircle {
    background: var(--danger-color);
}

.TestResultMessage > h3 {
    font-size: var(--mid-font-size);
    font-weight: 700;
}

.ButtonWrapper {
    margin-top: 30px;
}

/* Questions */
.QuestionWrapper {
    margin-bottom: 20px;
}

.Choices {
    display: flex;
    flex-direction: column;
    padding: 2px 0;
}

.NoVideo {
    padding: 20px;
}
