.DropzoneWrapper {
    display: flex;
    flex-direction: column;
    min-height: 75px;
}

.DropzoneInner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.Dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-width: 1px;
    border-radius: 6px;
    border: solid 1px rgba(234, 241, 255, 0.74);
    background-color: rgba(234, 241, 255, 0.74);
    outline: none;
    cursor: pointer;
    min-height: 70px;
    box-sizing: border-box;
    transition: border-color 0.3s ease-out, background-color 0.3s ease-out;
}

.FileItem {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.FileName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 30%;
}

.FileSize {
    color: var(--font-color-secondary);
}

.FileRemove {
    width: 20px;
    color: var(--danger-color);
    cursor: pointer;
}

.FileProgress {
    flex: 1;
    margin-bottom: 0;
    height: 6px;
    border-radius: 20px;
    box-shadow: 0 2px 2px rgb(224 230 237 / 46%), 1px 6px 7px rgb(224 230 237 / 46%);
    background-color: #ebedf2;
}
.ProgressBar {
    background: var(--success-color);
    height: 100%;
    box-shadow: 0 2px 4px rgb(27 170 113 / 15%), 0 8px 16px rgb(27 170 113 / 20%);
    border-radius: 16px;
}

.Dropzone .Label {
    text-align: center;
}

.FilesWrapper {
    padding: 10px;
}

.ErrorFileItem {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 5px;
    flex: 1;
}

.ErrorText {
    color: var(--danger-color);
}
