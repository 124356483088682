.Widgets {
    display: grid;
    gap: 20px;
}

@media (min-width: 1000px) {
    .Widgets {
        grid-template-columns: repeat(2, 1fr);
    }
}

.ButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 40px 0 20px;
}

.RandomPasswordWrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.RandomPasswordWrapper > label {
    margin-left: 10px;
}

.ButtonsWrapper label {
    color: var(--font-color-secondary);
    font-size: 1.3rem;
}

.TitleRowCol1 span {
    color: var(--font-color-secondary);
    margin-top: 5px;
}

.TitleRow {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.TitleRow button {
    margin-left: auto;
}

.TitleRowCol1 {
    flex: 2;
}
.TitleRowCol2 {
    flex: 1;

    align-self: flex-start;
}

.TitleRowCol1 span {
    line-height: 1.2;
    display: block;
}

.WidgetContent {
    margin-top: 20px;
}

.FormRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.FormRow:last-child {
    margin-bottom: 0;
}

.FormRow > label {
    flex: 1;
    margin-right: 10px;
}
.FormRow > div {
    flex: 2;
}

.IsMobile.Profile .ButtonsWrapper {
    flex-direction: column;
    align-items: flex-start;
}
.IsMobile.Profile .RandomPasswordWrapper {
    margin-left: 0;
}
