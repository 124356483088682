.ResetPasswordWrapper {
    height: 100%;
    padding: 60px 10%;
    box-sizing: border-box;
}

.ResetPasswordInner {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.VerticalSeparator {
    padding: 15px 0;
}

.ButtonWrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
}

.ButtonWrapper > button:last-child {
    margin-left: auto;
}
