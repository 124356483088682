.ModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    z-index: 100000;
}

.ModalWrapper.Show {
    opacity: 1;
    pointer-events: all;
}

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgb(22, 28, 36) 100%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
}

.ModalWrapper.Show .Backdrop {
    opacity: 1;
}

.Modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;
    position: fixed;
}

.ModalInner {
    width: 350px;
    max-width: 100%;
    max-height: 100%;
    background: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

.ModalWrapper.Show .ModalInner {
    transform: none;
}

.ModalContent {
    padding: 26px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalContent svg {
    width: 80px;
    height: 80px;
    color: var(--warning-color);
}
.ModalContent > h1 {
    margin-top: 10px;
}
.ModalContent > span {
    display: block;
    margin-top: 5px;
    color: var(--font-color-secondary);
}

.ModalFooter {
    padding: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e0e6ed;
}

.ModalFooter > span {
    margin-left: 20px;
    cursor: pointer;
    font-weight: 500;
}

@media (max-width: 500px) {
    .ModalInner {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}
