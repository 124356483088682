.LoginButtons {
    display: flex;
    align-items: center;
}

.LoginButton {
    border: solid 1px var(--border-color);
    padding: 10px 20px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
    white-space: nowrap;
}
.RegisterButton {
    background-color: var(--accent-color);
    border: solid 1px var(--accent-color);
    color: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    white-space: nowrap;
}
