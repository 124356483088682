.CoursesNum,
.CoursesComplete {
    display: flex;
    align-items: center;
    height: 50px;
}
.CoursesNum > span,
.CoursesComplete > span {
    font-weight: 700;
}

.CoursesNum > span:last-child,
.CoursesComplete > span:last-child {
    font-size: var(--large-font-size);
    margin-left: auto;
}
.CoursesComplete > span:last-child {
    color: var(--success-color);
}

.ProgressWrapper {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ProgressWrapper > span {
    font-weight: 700;
    margin-bottom: 7px;
}

.Progress {
    margin-bottom: 0;
    height: 6px;
    border-radius: 20px;
    box-shadow: 0 2px 2px rgb(224 230 237 / 46%), 1px 6px 7px rgb(224 230 237 / 46%);
    background-color: #ebedf2;
}

.ProgressBar {
    background: var(--success-color);
    height: 100%;
    box-shadow: 0 2px 4px rgb(27 170 113 / 15%), 0 8px 16px rgb(27 170 113 / 20%);
    border-radius: 16px;
    /* background-image: linear-gradient(to right, #009688 0%, #25d5e4 100%); */
}

.Overview {
    padding: 20px 25px 25px;
    border-bottom: solid 1px var(--border-color);
}

.Certification {
    padding: 25px;
}
.CertificationTitle {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.CertificationTitle > span {
    font-weight: 700;
}
.CertificationTitle > svg {
    width: 20px;
    margin-left: auto;
    fill: rgba(136, 142, 168, 0.1);
}

.CertificationMessage {
    color: var(--font-color-secondary);
    font-size: var(--small-font-size);
}

.ButtonWrapper {
    margin-top: 20px;
}

.Deminimis {
    padding: 25px;
    border-bottom: solid 1px var(--border-color);
}

.Deminimis .CertificationMessage > span {
    color: var(--warning-color);
    font-size: var(--font-size);
    cursor: pointer;
}

.PdfLink {
    text-decoration: none;
    color: var(--warning-color);
    font-size: var(--font-size);
}

.Certification > span {
    color: var(--font-color-secondary);
    font-size: var(--small-font-size);
}
