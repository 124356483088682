.WebGis {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.ContentInner {
    flex: 1;
    display: flex;
}

.Map {
    width: 100%;
    height: 100%;
    flex: 1;
}

.Map > div {
    width: 100%;
    height: 100%;
}

.MapSidebar {
    position: relative;
    height: 100%;
    flex: 0 0 350px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    z-index: 10;
    transition: margin-left 0.2s cubic-bezier(0, 0, 0.2, 1);
    margin-left: 0;
}
.MapSidebar.Closed {
    margin-left: -350px;
}
.MapSidebar.Closed .CloseSidebar svg {
    transform: rotate(180deg);
}

.CloseSidebar {
    position: absolute;
    top: calc(50% - 24px);
    right: -24px;
    border: 0;
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    width: 23px;
    height: 48px;
    cursor: pointer;
    border-left: 1px solid rgb(218, 220, 224);
    border-radius: 0 8px 8px 0;
    background: #fff 7px center/7px 10px no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CloseSidebar svg {
    width: 20px;
    margin-left: -3px;
}

/* Tabs */
.MapTabs {
    display: flex;
    align-items: center;
}
.MapTabs > div {
    flex: 1;
    text-align: center;
    padding: 19px 10px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease-out;
}
.MapTabs > div.Active {
    background-color: var(--action-color);
    color: #fff;
    font-weight: 700;
}

.ContentInner.IsMobile {
    flex-direction: column;
}
.ContentInner.IsMobile.ShowMap .MapSidebar {
    display: none;
}
.ContentInner.IsMobile.ShowBusinesses .Map {
    display: none;
}

.ContentInner.IsMobile .MapSidebar {
    flex: 1;
}
.ContentInner.IsMobile .CloseSidebar {
    display: none;
}

:local(.Map) :global(.leaflet-popup) {
    width: 300px;
}

:local(.Map) :global(.leaflet-popup-content-wrapper) {
    border-radius: 6px;
}
:local(.Map) :global(.leaflet-popup-content) {
    margin: 0;
    width: 100% !important;
}
:local(.Map) :global(.leaflet-popup-close-button > span) {
    background: #4c505e;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    top: 5px;
}
