.Video {
    padding: 20px 0;
}

.Video h3 {
    margin-bottom: 10px;
    color: var(--font-color-heading);
    font-weight: 700;
}

.CustomIcon {
    margin-right: 20px;
    color: var(--font-color);
    fill: var(--font-color);
    stroke: var(--font-color);
    width: 18px;
}

.WineType {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.WineSubs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: solid 1px var(--border-color);
    padding: 10px;
    padding-top: 0;
    box-sizing: border-box;
    flex: 1;
    align-items: center;
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
}
.WineSubs > h4 {
    margin: 0;
    line-height: 15px;
    width: 100%;
    padding: 10px;
    background: var(--border-color);
    text-align: center;
}
.WineSubs > img {
    width: 300px;
    max-width: 100%;
}

.WineSubs:hover {
    background-color: #f5f5f5;
}

.Guidelines {
    margin-bottom: 30px;
}

.Link {
    color: var(--action-color);
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 700px) {
    .WineType {
        flex-direction: column;
    }
}
