.Logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Logo img {
    height: 80px;
    cursor: pointer;
}

.Small.Logo img {
    height: 50px;
}
