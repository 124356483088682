.Page404 {
    height: 100%;
    padding: 60px 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    background-color: var(--neutral-color);
}

.Page404Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex: 1;
}

.Page404Inner .Title {
    font-size: 33px;
    font-weight: 700;
    margin: 0;
    color: var(--font-color-heading);
    text-align: center;
}

.Page404Inner .Subtitle {
    font-size: var(--font-size-midlarge);
    color: var(--font-color);
    font-weight: 600;
    margin-bottom: 3rem;
    margin-top: 0.25rem;
    text-align: center;
}

.Page404Inner > img {
    max-width: 100%;
    width: 350px;
    margin-bottom: 50px;
}

.ButtonWrapper {
    margin-top: 20px;
}
