.LoginWrapper {
    height: 100%;
    box-sizing: border-box;
}

.Login {
    display: flex;
    height: 100%;
    margin: 0 auto;
}

.ColumnA {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
    box-sizing: border-box;
}

.ColumnA img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 40px;
}
.ColumnA h1 {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    line-height: 1.1;
}
.ColumnA span {
    text-align: center;
    max-width: 300px;
    line-height: 2.2rem;
}

.ColumnBWrapper {
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}

.ColumnB {
    padding: 50px 40px 0 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}

.LoginWrapper.IsMobile .ColumnB {
    border-radius: 0;
}
.LoginWrapper.IsMobile .ColumnA {
    display: none;
}

.ColumnB img {
    width: 125px;
}

.LogoWrapper {
    text-align: right;
}

.ColumnBContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 40px;
}

.ColumnBContent form {
    width: 100%;
    margin-top: 40px;
}

.ColumnBContent h2 {
    font-size: 3.5rem;
    margin-bottom: 10px;
}

.VerticalSeparator {
    padding: 10px 0;
}

.Remember {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.Remember .ForgotPassword {
    color: var(--warning-color);
    cursor: pointer;
    margin-left: auto;
}

.ButtonWrapper {
    width: 100%;
    margin-top: 40px;
}

.NoAccount {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.CreateAccount {
    color: var(--warning-color);
    cursor: pointer;
    margin-left: 5px;
}

.TermsLink {
    color: var(--warning-color);
    cursor: pointer;
    margin-left: 5px;
}

.IsCompany {
    flex: 1;
}

.PdfLink {
    color: var(--warning-color);
    text-decoration: none;
    cursor: pointer;
}
.PdfLink > span {
    color: var(--warning-color);
}

.FirstRowWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
