.Routes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
    gap: 60px;
}

.Route {
    display: flex;
    flex-direction: column;
}

.RouteImage {
    width: 100%;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.Route > h3 {
    font-weight: 700;
    margin-bottom: 10px;
}

.Route .RouteDescription {
    flex: 1;
}

.ButtonWrapper {
    margin-top: 20px;
}

.AdditionalData {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.AdditionalData > div {
    display: flex;
    align-items: center;
}
.AdditionalData > div > svg {
    width: 20px;
    margin-right: 5px;
}
.AdditionalData > div:last-child {
    margin-left: auto;
}
.AdditionalDataResult {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 700;
    font-size: var(--mid-font-size);
}

.SuggestedRoutes.IsMobile .Routes {
    flex-direction: column;
}
.SuggestedRoutes.IsMobile .Route {
    max-width: unset;
}
