.Geolocation {
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease-out;
}
.Geolocation:hover {
    background-color: var(--neutral-color);
}
.Geolocation svg {
    color: var(--action-color);
    margin-right: 15px;
    width: 20px;
}

.LearnMoreButton {
    color: #000 !important;
    font-weight: 700;
    border: solid 1px #000;
    padding: 5px;
    margin-right: 10px;
    border-radius: 3px;
    cursor: pointer;
}

:global(#geolocation.hidden) {
    display: none;
}
