.TopPartWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0086dc;
    width: 100%;
}
.TopPart {
    display: flex;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    padding: 50px 25px;
    box-sizing: border-box;
    width: 100%;
    gap: 20px;
}

.RestPartWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 100%;
}

.RestPart {
    padding: 50px 25px;
    box-sizing: border-box;
    width: 100%;
}

.TitleWrapper {
    flex: 1;
}

.TitleWrapper h1 {
    margin-bottom: 10px;
    font-weight: 700;
    line-height: 1.1;
    color: #fff;
    font-size: 40px;
}
.TitleWrapper span {
    max-width: 300px;
    color: #fff;
}

.HomeButtons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}

.HomeButtonsMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.ImageWrapper {
    flex: 1;
    text-align: right;
}
.ImageWrapper img {
    width: 100%;
    max-width: 500px;
}

.TopPartWrapper.IsSmallScreen .TopPart {
    flex-direction: column;
}

.TopPartWrapper.IsSmallScreen .ImageWrapper img {
    max-width: 300px;
}
.TopPartWrapper.IsSmallScreen .TitleWrapper {
    text-align: center;
    margin-top: 10px;
}

.RestPart ul {
    list-style-type: disc;
    list-style-position: inside;
}

.RestPart a {
    color: var(--action-color);
    text-decoration: none;
}

.LogoWrapper img {
    max-width: 300px;
    width: 100%;
}

@media (min-width: 700px) {
    .TopPart {
        padding: 50px;
    }
    .RestPart {
        padding: 50px;
    }
    .TitleWrapper h1 {
        font-size: 40px;
    }
}

@media (min-width: 1600px) {
    .TopPart {
        width: 1400px;
        padding: 50px;
    }
    .RestPart {
        width: 1400px;
        padding: 50px;
    }
    .TitleWrapper h1 {
        font-size: 50px;
    }
}
