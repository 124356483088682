.MainSelectWrapper {
    width: 100%;
    max-width: 300px;
}
.MainSelectWrapper.FullWidth {
    max-width: 100%;
}

.MainSelectWrapper.Narrow {
    width: 170px;
}

.MainSelectWrapper.VeryNarrow {
    width: 75px;
}

.MainSelect {
    width: 100%;
    border: 1px solid #bfc9d4;
    color: #3b3f5c;
    padding: 8px 10px;
    padding: 1rem 1.25rem;
    border-radius: 6px;
    height: 43px;
    transition: none;
    box-sizing: border-box;
    display: block;
    appearance: none;
    outline: none;
    background-color: var(--background-color);
    appearance: none;
    background: #fff
        url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='none' stroke='%23d3d3d3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")
        right 10px center no-repeat;
    padding: 7px 18px 7px 14px;
    font-size: var(--normal-font-size);
    letter-spacing: 0.025rem;
}

.MainSelect:focus,
.MainSelect:focus-visible {
    box-shadow: none;
    border-color: var(--action-color);
    color: #3b3f5c;
    background-color: var(--background-color);
}

.MainSelect:disabled {
    background-color: var(--neutral-color);
}

.MainSelectWrapper label {
    margin-bottom: 5px;
    display: block;
}

.MainSelect:disabled {
    background-color: #f1f2f3;
    cursor: no-drop;
    color: #d3d3d3;
}

.MainSelectWrapper.IsValid .MainSelect {
    border-color: #009688;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23009688' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
}
.MainSelectWrapper.IsInvalid .MainSelect {
    border-color: #dc3545;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e7515a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
}

/* Dark */
body[data-theme='dark'] .MainSelect {
    border: 1px solid var(--background-color);
    color: #009688;
    background-color: #1b2e4b;
}
body[data-theme='dark'] .MainSelect:focus,
body[data-theme='dark'] .MainSelect:focus-visible {
    box-shadow: none;
    border-color: #3b3f5c;
    color: #22c7d5;
    background-color: #1b2e4b;
}
body[data-theme='dark'] .MainSelect:-webkit-autofill,
body[data-theme='dark'] .MainSelect:-webkit-autofill:hover,
body[data-theme='dark'] .MainSelect:-webkit-autofill:focus,
body[data-theme='dark'] .MainSelect:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
body[data-theme='dark'] .MainSelect:-webkit-autofill {
    -webkit-text-fill-color: #009688 !important;
}
body[data-theme='dark'] .MainSelect:disabled {
    background-color: #3b3f5c;
    cursor: no-drop;
    color: #888ea8;
}
