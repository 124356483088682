.MainTextareaWrapper {
    width: 100%;
    max-width: 300px;
}
.MainTextareaWrapper.FullWidth {
    max-width: 100%;
}

.MainTextareaWrapper.Narrow {
    width: 170px;
}

.MainTextareaWrapper.VeryNarrow {
    width: 70px;
}

.MainTextarea {
    width: 100%;
    border: 1px solid #bfc9d4;
    color: #3b3f5c;
    padding: 8px 10px;
    padding: 1rem 1.25rem;
    border-radius: 6px;
    height: auto;
    transition: none;
    box-sizing: border-box;
    display: block;
    appearance: none;
    outline: none;
    background-color: var(--background-color);
    font-size: var(--font-size);
    font-family: var(--font-family);
    resize: vertical;
}

.MainTextarea:focus,
.MainTextarea:focus-visible {
    box-shadow: none;
    border-color: var(--action-color);
    color: #3b3f5c;
    background-color: var(--background-color);
}

.MainTextarea:disabled {
    background-color: var(--neutral-color);
}

.MainTextareaWrapper label {
    margin-bottom: 5px;
    display: block;
}

.MainTextarea:disabled {
    background-color: #f1f2f3;
    cursor: no-drop;
    color: #d3d3d3;
}

.MainTextareaWrapper.IsValid .MainTextarea {
    border-color: #009688;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23009688' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
}
.MainTextareaWrapper.IsInvalid .MainTextarea {
    border-color: #dc3545;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e7515a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
}
