.Widgets {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.TitleRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.TitleRow h2 {
    flex: 1;
}

.TableActions {
    display: flex;
    align-items: center;
    gap: 5px;
}

.ActionBtn {
    cursor: pointer;
}

.IconWrapper {
    display: flex;
    align-items: center;
}
.Check {
    width: 20px;
    color: var(--success-color);
}
.NoCheck {
    width: 20px;
    color: var(--danger-color);
}

/* Forms */
.FormRow {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}
.FormRow:last-child {
    margin-bottom: 0;
}

.FormRow > label {
    flex: 0 0 100px;
    margin-right: 10px;
    font-weight: 700;
}

.FormRow > div {
    flex: 2;
}

.FormRow > div {
    flex: 2;
}

.ButtonWrapper {
    padding: 18px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e0e6ed;
    margin-top: 20px;
    margin: 20px -26px -18px -26px;
}

.MinimumPasswordText {
    position: absolute;
    left: 0;
    top: 27px;
    font-size: var(--very-small-font-size);
    color: var(--warning-color);
}

/* Map */
.MapWrapper {
    margin-top: 20px;
}
.MapWrapper > span {
    font-weight: 700;
}
.Map {
    height: 300px;
}
.Map > div {
    height: 100%;
}
.Coordinates {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.Coordinates > div {
    margin-right: 20px;
}
.Coordinates > div:last-child {
    margin-right: 0;
}

.DropzoneWrapper {
    margin-top: 20px;
}

/* Images */
.ImagesWrapper {
    margin-top: 20px;
}
.ImagesWrapper > span {
    font-weight: 700;
}

.ImagesList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.ImageItem {
    position: relative;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.DeleteImage {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease-in-out;
    border-radius: 3px;
}

.DeleteImage svg {
    position: absolute;
    width: 26px;
    top: 50%;
    margin-top: -13px;
    color: var(--danger-color);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.DeleteImage:hover {
    background-color: rgba(0, 0, 0, 0.5);
}
.DeleteImage:hover svg {
    opacity: 1;
}

.Tabs {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.Tab {
    font-size: var(--menu-font-size);
    font-weight: 600;
    cursor: pointer;
}
.Tab.Active {
    color: var(--action-color);
}

/* Questions */
.Questions {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.Question {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.QuestionNumber {
    width: 30px;
    height: 30px;
    background-color: var(--grey-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.QuestionLang label {
    margin-bottom: 3px;
    display: block;
    font-weight: 600;
}

.FirstRow {
    display: flex;
    align-items: center;
}
.QuestionButtons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

.ChoiceItem {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ChoiceItem label {
    margin-bottom: 0;
}
.ChoiceItem input {
    margin: 0 !important;
}
.ChoiceItem > svg {
    color: var(--danger-color);
    cursor: pointer;
}

.Bullet {
    flex: 0 0 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--font-color);
}
