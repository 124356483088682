.Widgets {
    display: grid;
    gap: 20px;
}

@media (min-width: 1000px) {
    .Widgets {
        grid-template-columns: repeat(2, 1fr);
    }
}

.CoursesWrapper {
    padding: 20px 0 20px;
}

.FlexColumn {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.TitleRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.TitleRow h2 {
    flex: 1;
}
