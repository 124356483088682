.Badge {
    position: relative;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
}

.Badge > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--small-font-size);
    line-height: 1;
    margin-top: 8px;
}

.Badge > div.Hide {
    display: none;
}

.Badge svg {
    width: 19px;
    height: 19px;
    stroke-width: 2;
}

.Badge.Success {
    background-color: #ddf5f0;
}
.Badge.Danger {
    background-color: #fbeced;
}
.Badge.Warning {
    background-color: #fcf5e9;
}
.Badge.Info {
    background-color: #f2eafa;
}

.Badge.Success svg,
.Badge.Success > div {
    color: #00ab55;
}
.Badge.Danger svg,
.Badge.Danger > div {
    color: #e7515a;
}
.Badge.Warning svg,
.Badge.Warning > div {
    color: #e2a03f;
}
.Badge.Info svg,
.Badge.Info > div {
    color: #805dca;
}

.Badge.Small {
    width: 36px;
    height: 31px;
    border-radius: 6px;
}
.Badge.Small svg {
    width: 15px;
    height: 15px;
}

.Badge.Large {
    width: 66px;
    height: 60px;
    padding: 10px;
}

.Badge.Circle {
    border-radius: 50%;
}

/* Dark */
body[data-theme='dark'] .Badge.Success {
    background-color: #009688;
}
body[data-theme='dark'] .Badge.Success svg {
    color: #e6ffbf;
}
body[data-theme='dark'] .Badge.Success > div {
    color: #e6ffbf;
}

body[data-theme='dark'] .Badge.Danger {
    background-color: #e7515a;
}
body[data-theme='dark'] .Badge.Danger svg {
    color: #efced0;
}
body[data-theme='dark'] .Badge.Danger > div {
    color: #efced0;
}

body[data-theme='dark'] .Badge.Warning {
    background-color: #e2a03f;
}
body[data-theme='dark'] .Badge.Warning svg {
    color: #ffeccb;
}
body[data-theme='dark'] .Badge.Warning > div {
    color: #ffeccb;
}

body[data-theme='dark'] .Badge.Info {
    background-color: #805dca;
}
body[data-theme='dark'] .Badge.Info svg {
    color: #dccff7;
}
body[data-theme='dark'] .Badge.Info > div {
    color: #dccff7;
}
