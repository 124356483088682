.SidebarTitle {
    background-color: var(--grey-color);
    padding: 20px 20px 18px;
    font-weight: 700;
    flex: 0 0 56px;
    box-sizing: border-box;
}

.Search {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: solid 1px var(--border-color);
    flex: 0 0 45px;
    box-sizing: border-box;
}
.Search input {
    border: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 0;
}
.Search input:focus-visible {
    outline: none;
    border: none;
}
.Search svg {
    width: 20px;
    margin-left: auto;
    color: var(--font-color-secondary);
}

.Businesses {
    height: calc(100% - 50px);
    z-index: 1;
}

.NoResults {
    padding: 20px;
    display: block;
    color: var(--font-color-secondary);
}
