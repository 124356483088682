.CourseRow {
    display: flex;
    align-items: center;
    padding: 12px 25px;
    background-color: #fff;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
}

.CourseRow:hover,
.CourseRow.Selected {
    background-color: #d9e3ef;
}
.CourseRow:hover .Title > span:last-child,
.CourseRow.Selected .Title > span:last-child {
    color: var(--font-color);
}

.Number {
    font-weight: 700;
    flex: 0 0 28px;
}

.Title {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.Title > span:first-child {
    font-weight: 700;
}
.Title > span:last-child {
    font-size: var(--small-font-size);
    color: var(--font-color-secondary);
    transition: color 0.3s ease-out;
}

.IconsWrapper {
    display: flex;
    align-items: center;
    flex: 0 0 64px;
    justify-content: flex-end;
    gap: 10px;
}

.CourseRow .ReadCourse {
    color: var(--success-color);
}

.CourseRow .PassedAssessment {
    width: 20px;
    fill: rgba(136, 142, 168, 0.1);
    color: var(--font-color);
}
