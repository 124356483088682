.MainLangWrapper {
    margin-right: 10px;
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
}

.MainLangWrapper img {
    width: 36px;
    cursor: pointer;
    border: solid 1px var(--border-color);
    border-radius: 6px;
}

.DropdownMenu {
    position: absolute;
    top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: auto;
    background: #fff;
    padding: 16px;
    left: -16px;
    border-radius: 6px;
    z-index: 9999;
    color: #212529;
    visibility: hidden;
    opacity: 0;
    transition: top 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
    box-shadow: 0 10px 30px 0 rgb(31 45 61 / 10%);
    border: 1px solid #ebedf2;
}

.DropdownMenu.Show {
    opacity: 1;
    visibility: visible;
    top: 40px;
}
