.MainSwitchWrapper {
    display: flex;
    align-items: center;
}

.MainSwitchWrapper label {
    margin-left: 10px;
}

.MainSwitch {
    vertical-align: top;
    background-color: #bfc9d4;
    background-repeat: no-repeat;
    border: 2px solid #bfc9d4;
    appearance: none;
    width: 42px;
    height: 25px;
    cursor: pointer;
    margin: 0;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' style='fill: %231b2e4b;'%3E%3Cpath d='M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.207 12.793-1.414 1.414L12 13.414l-2.793 2.793-1.414-1.414L10.586 12 7.793 9.207l1.414-1.414L12 10.586l2.793-2.793 1.414 1.414L13.414 12l2.793 2.793z'%3E%3C/path%3E%3C/svg%3E");
    background-position: left center;
    border-radius: 2em;
    box-sizing: border-box;
    transition: background-position 0.15s ease-in-out;
}

.MainSwitch.Checked {
    background-color: var(--action-color);
    border-color: var(--action-color);
    background-position: right center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' style='fill: rgba(255, 255, 255, 1);'%3E%3Cpath d='M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z'%3E%3C/path%3E%3C/svg%3E");
}

.MainSwitch.Checked[color='action'] {
    background-color: var(--action-color);
    border-color: var(--action-color);
}

.MainSwitch.Checked[color='success'] {
    background-color: var(--success-color);
    border-color: var(--success-color);
}

.MainSwitch.Checked[color='warning'] {
    background-color: var(--warning-color);
    border-color: var(--warning-color);
}

.MainSwitch.Checked[color='danger'] {
    background-color: var(--danger-color);
    border-color: var(--danger-color);
}

.MainSwitch.Checked[color='info'] {
    background-color: #805dca;
    border-color: #805dca;
}

.MainSwitch.Checked[color='grey'] {
    background-color: #bfc9d4;
    border-color: #bfc9d4;
}

/* Dark */
body[data-theme='dark'] .MainSwitch:not(.Checked) {
    background-color: #888ea8;
    border: 2px solid #888ea8;
}
