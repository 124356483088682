.Footer {
    padding: 20px 25px;
    width: 100%;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.Social {
    display: flex;
    align-items: center;
    gap: 15px;
}

.Social a {
    display: flex;
    align-items: center;
}

.Social svg {
    height: 20px;
    cursor: pointer;
}

.Footer span {
    color: var(--font-color);
    margin-left: auto;
}

@media (min-width: 1600px) {
    .Footer {
        width: 1400px;
        padding: 20px 50px;
    }
}
@media (min-width: 700px) {
    .Footer {
        padding: 20px 50px;
    }
}
