.HighlightedText {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-right: 64px solid;
    border-radius: var(--border-radius);
    padding: 17px;
    width: 100%;
    box-sizing: border-box;
}

.HighlightedText > span {
    width: 100%;
    text-align: center;
}
.HighlightedText > span > strong {
    margin-right: 10px;
}

.HighlightedText > svg {
    color: #fff;
    width: 25px;
    right: -49px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    -webkit-font-smoothing: antialiased;
    margin-right: 5px;
}

.HighlightedText::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    margin-top: -8px;
    left: auto;
    right: 0;
    border-left: 0;
    border-right: 8px solid;
    border-right-color: inherit;
}

.HighlightedText.Warning {
    border-color: rgba(226, 160, 63, 0.55);
    background-color: #fcf5e9;
}
.HighlightedText.Warning > span > strong {
    color: var(--warning-color);
}

.HighlightedText.Info {
    border-color: rgba(67, 97, 238, 0.55);
    background-color: #f2f3f7;
}
.HighlightedText.Info > span > strong {
    color: var(--action-color);
}

/* Dark */
body[data-theme='dark'] .HighlightedText.Warning {
    background-color: #282625;
}
body[data-theme='dark'] .HighlightedText.Warning > span {
    color: #e0e6ed;
}

body[data-theme='dark'] .HighlightedText.Info {
    background-color: #28282c;
}
body[data-theme='dark'] .HighlightedText.Info > span {
    color: #e0e6ed;
}
