.MainRadioWrapper {
    padding: 2px 0;
}

.MainRadio {
    position: relative;
    cursor: pointer;
}

.MainRadio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.MainRadioIndicator {
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #e0e6ed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 50%;
}

.MainRadio input:checked ~ span.MainRadioIndicator {
    background: var(--action-color);
}

.MainRadioText {
    margin-left: 25px;
}
