.MainCheckboxWrapper input {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    /* creating a custom design */
    width: 20px;
    height: 20px;
    margin-right: 12px;
    position: relative;
    border-radius: 0.25em;
    background-color: var(--grey-color);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.MainCheckboxWrapper label {
    display: flex;
    align-items: center;
}

.MainCheckboxWrapper label span {
    line-height: 1;
}

.MainCheckboxWrapper input:focus {
    background-color: #d4dbe3;
}

.MainCheckboxWrapper input.Checked {
    background-color: var(--action-color);
    position: relative;
}

.MainCheckboxWrapper input.Checked::before {
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
    position: absolute;
    right: 3px;
    top: 0;
}

.MainCheckboxWrapper input[type='checkbox']:disabled {
    opacity: 0.8;
    pointer-events: none;
}

/* Dark */
body[data-theme='dark'] .MainCheckboxWrapper input {
    background-color: #515365;
}
body[data-theme='dark'] .MainCheckboxWrapper input:focus {
    background-color: #727486;
}
body[data-theme='dark'] .MainCheckboxWrapper input.Checked {
    background-color: var(--action-color);
}
