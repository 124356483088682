:global(.TermsPart) {
    margin-bottom: 30px;
}
:global(.TermsPart:last-child) {
    margin-bottom: 0;
}

:global(.TermsPart h4) {
    margin-bottom: 10px;
    margin-top: 0;
}
:global(.TermsPart ul) {
    list-style: disc;
    list-style-position: inside;
}
