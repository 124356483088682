.NavigationMobile {
    width: 100%;
}
.Navigation {
    display: flex;
    width: 100%;
    overflow-x: auto;
}

.NavigationItem {
    flex: 1;
}

.NavigationItem a {
    position: relative;
    text-decoration: none;
    min-width: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;

    transition: background-color 0.3s ease-out;
}

.NavigationItem a svg {
    width: 20px;
    height: 20px;
    color: var(--font-color);
    transition: color 0.3s ease-out;
}

.NavigationItem a span {
    text-align: center;
    transition: color 0.3s ease-out;
}

.NavigationItem a:active,
.NavigationItem a.active {
    background-color: var(--action-color);
}

.NavigationItem a:active svg,
.NavigationItem a.active svg {
    color: #fff;
}
.NavigationItem a:active span,
.NavigationItem a.active span {
    color: #fff;
}
