.LoaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e4e9ef;
    z-index: 10000;
    pointer-events: none;
    opacity: 0;
    /* transition: opacity 0.2s ease-in; */
}

.Absolute.LoaderWrapper {
    position: absolute;
}

.Show.LoaderWrapper {
    pointer-events: all;
    opacity: 1;
}

.LoaderWrapper:not(.Show) {
    transition: opacity 0.2s ease-in;
}

.Loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.Loader div {
    position: absolute;
    border: 4px solid var(--font-color);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.Loader div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
