body {
    --grey-color: #bfc9d4;
    --neutral-color: #fafafa;
    --background-color: #fff;

    --action-color: #0086dc;
    --danger-color: #e7515a;
    --warning-color: #e2a03f;
    --success-color: #009688;

    --accent-color: #4c505e;
    --border-color: #e0e6ed;

    --font-size-midlarge: 1.6rem;
    /* --font-family: 'Nunito', sans-serif; */
    --font-family: 'Noto Sans', sans-serif;
    --font-color-heading: #4c505e;
    --font-color: #4c505e;
    --font-color-secondary: #888ea8;

    --large-font-size: 3rem;
    --mid-font-size: 2rem;
    --menu-font-size: 1.5rem;
    --normal-font-size: 1.4rem;
    --small-font-size: 1.3rem;
    --very-small-font-size: 1.1rem;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    width: 100%;
    height: 100% !important;
    overflow: hidden;
}

html {
    font-size: 62.5%;
}

body {
    background-color: var(--neutral-color);
    letter-spacing: 0.025rem;
}

h1 {
    font-weight: 500;
    margin: 0;
    color: var(--font-color-heading);
    font-size: var(--large-font-size);
    font-family: var(--font-family);
    line-height: 1.2;
}
h2 {
    font-weight: 500;
    margin: 0;
    color: var(--font-color-heading);
    font-family: var(--font-family);
    line-height: 1.2;
}
h3 {
    font-weight: 500;
    margin: 0;
    color: var(--font-color-heading);
    font-family: var(--font-family);
    line-height: 1.2;
}

p,
span,
div,
input {
    font-weight: 400;
    color: var(--font-color);
    font-size: 1.4rem;
    font-family: var(--font-family);
    line-height: 1.5;
    letter-spacing: 0.025rem;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0;
}

#root {
    height: 100%;
}

/* Table */
.MuiTable-root {
    /* min-height: 230px; */
    position: relative;
}
.MuiToolbar-root {
    overflow: visible !important;
}

.NoDataMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 180px;
}

/* svg icon table */
.ant-empty-img-1 {
    fill: #aeb8c2;
}
.ant-empty-img-2 {
    fill: #f5f5f7;
}
.ant-empty-img-3 {
    fill: #dce0e6;
}
.ant-empty-img-4 {
    fill: #fff;
}
.ant-empty-img-5 {
    fill: #f5f5f5;
    fill-opacity: 0.8;
}

.fa-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
}

/* Map */
.leaflet-container {
    z-index: 1;
}

.leaflet-draw-tooltip > span {
    color: #fff;
}

.leaflet-draw {
    display: none;
}

/* Snackbar */
.SnackbarContainer-root {
    z-index: 100001 !important;
}

.SnackbarContent-root div,
.SnackbarContent-root span {
    color: #fff;
}

.SnackbarContent-root.SnackbarItem-variantError {
    background-color: var(--danger-color) !important;
}
.SnackbarContent-root.SnackbarItem-variantSuccess {
    background-color: var(--success-color) !important;
}
.SnackbarContent-root.SnackbarItem-variantWarning {
    background-color: var(--warning-color) !important;
}
.SnackbarContent-root.SnackbarItem-variantInfo {
    background-color: var(--action-color) !important;
}

.SnackbarItem-action svg {
    width: 20px;
    cursor: pointer;
}

/* Swal */
.swal2-container {
    z-index: 10003;
}

.swal2-container .swal2-title {
    font-size: 3rem;
    color: var(--font-color-heading);
}
.swal2-container .swal2-html-container {
    font-size: 1.6rem;
    color: var(--font-color);
    margin-top: 5px;
}

.swal2-popup {
    width: 36em;
    padding: 0 0 25px;
}

.swal2-icon-content {
    color: #f8bb86;
}

body.swal2-height-auto {
    height: 100% !important;
}

.swal2-confirm,
.swal2-cancel {
    outline: 0;
    border: 0 !important;
    padding: 10px;
    border-radius: 6px !important;
    color: #fff !important;
    padding: 10px 20px !important;
    margin: 0 5px !important;
    transition: box-shadow 0.3s ease-out;
}
.swal2-confirm {
    background-color: var(--action-color) !important;
    box-shadow: 0 10px 20px -10px rgb(27 85 226 / 59%) !important;
}
.swal2-cancel {
    background-color: var(--danger-color) !important;
    box-shadow: 0 10px 20px -10px rgb(231 81 90 / 59%) !important;
}
.swal2-confirm:hover,
.swal2-cancel:hover {
    box-shadow: none !important;
}
