/* Viewer */
.ViewerWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.Viewer {
    min-height: 400px;
    overflow: hidden;
    position: relative;
    flex: 1;
}

.ViewerWrapper.FullScreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10;
    display: flex;
    flex-direction: column;
}
.ViewerWrapper.FullScreen .Viewer {
    height: unset;
    flex: 1;
}
.LoaderWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
    position: absolute;
}

.ImageCarousel {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
}

.Swiper {
    width: 100%;
    height: 100%;
}

:local(.Swiper) :global(.swiper-slide) {
    overflow: hidden;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Swiper img {
    max-height: 100%;
    max-width: 100%;
}

/* Controls */
.Controls {
    display: flex;
    align-items: center;
    padding: 19px 20px 19px 15px;
    border-top: solid 1px var(--border-color);
    user-select: none;
}

.Previous,
.Next,
.Maximize {
    display: flex;
    align-items: center;
}

.Next {
    margin-left: 20px;
    cursor: pointer;
}
.Previous {
    cursor: pointer;
}

.Previous svg,
.Next svg {
    width: 18px;
}

.Maximize {
    margin-left: 20px;
    cursor: pointer;
}

.Maximize svg {
    width: 16px;
}

.PageNum {
    margin-left: auto;
}
