:local(.MyTable) :global(.MuiPaper-root) {
    background-color: transparent;
    box-shadow: none;
}
:local(.MyTable) :global(.MuiPaper-root > .MuiToolbar-root:first-child) {
    z-index: 2;
}
:local(.MyTable) :global(.MuiToolbar-root) {
    background-color: transparent;
    margin-bottom: 15px;
    box-shadow: none;
}
:local(.MyTable) :global(.MuiToolbar-root > .MuiBox-root > .MuiBox-root) {
    width: 100%;
}
:local(.MyTable) :global(.MuiToolbar-root > .MuiBox-root) {
    flex-wrap: wrap;
    gap: 10px;
}
:local(.MyTable) :global(.MuiToolbar-root p) {
    font-size: var(--normal-font-size);
    font-family: var(--font-family);
}
:local(.MyTable) :global(.MuiToolbar-root div) {
    font-size: var(--normal-font-size);
    font-family: var(--font-family);
    align-items: center;
}
:local(.MyTable) :global(.MuiTableRow-head) {
    background-color: rgba(234, 241, 255, 0.74);
    box-shadow: none;
}
:local(.MyTable) :global(.MuiTableRow-head th) {
    border-bottom: 0;
    font-family: var(--font-family);
    padding: 1rem 1.3rem;
    font-size: var(--normal-font-size);
    color: var(--font-color);
    min-width: unset;
    letter-spacing: 0.025rem;
}
:local(.MyTable) :global(.MuiTableRow-head th div) {
    letter-spacing: 0.025rem;
    font-weight: 700;
}
:local(.MyTable) :global(.MuiTableBody-root tr) {
    background-color: transparent;
    border-bottom: 1px solid #e0e6ed;
}
:local(.MyTable) :global(.MuiTableBody-root td) {
    font-family: var(--font-family);
    padding: 1rem 1.3rem;
    font-size: var(--normal-font-size);
    color: var(--font-color);
    min-width: unset;
    border-bottom: 0;
    letter-spacing: 0.025rem;
}

:local(.MyTable) :global(.MuiFormControl-root .MuiOutlinedInput-root) {
    font-family: var(--font-family);
}
:local(.MyTable) :global(.MuiFormControl-root fieldset) {
    top: -9px;
    border-color: #e0e6ed !important;
    border-width: 1px !important;
    border-radius: 6px;
}
:local(.MyTable) :global(.MuiFormControl-root input) {
    font-size: var(--normal-font-size);
    font-family: var(--font-family);
    letter-spacing: 0.025rem;
}

:local(.MyTable) :global(.MuiFormControl-root svg) {
    color: var(--font-color);
    width: 20px;
    height: 20px;
}

:local(.MyTable) :global(.MuiFormControl-root .MuiOutlinedInput-root > svg) {
    margin-right: 10px !important;
}

:local(.MyTable) :global(.MuiTableSortLabel-root) {
    margin-left: 10px;
}
:local(.MyTable) :global(.MuiTableSortLabel-root > svg) {
    color: var(--font-color);
}
:local(.MyTable) :global(.MuiBox-root) {
    padding: 0;
}
:local(.MyTable) :global(.MuiTablePagination-root) {
    display: none;
}
:local(.MyTable) :global(.MuiTypography-root) {
    color: var(--font-color);
    font-size: var(--normal-font-size);
    font-family: var(--font-family);
}

.TopLeftBar {
    display: flex;
    align-items: center;
}
.RowsPerPage {
    margin-right: 10px;
    display: flex;
    align-items: center;
}
.RowsPerPage > span {
    margin-right: 5px;
}

.BottomBar {
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}
.PageCount {
    white-space: normal;
    color: var(--action-color);
    font-weight: 600;
    border: 1px solid #e0e6ed;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 13px !important;
}
.Pagination ul {
    margin: 3px 0;
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    list-style: none;
    white-space: nowrap;
    flex-wrap: wrap;
}
.PaginationPrevious > div,
.PaginationNext > div {
    border-radius: 8px;
    padding: 0;
    height: 33px;
    width: 33px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    /* background: #1b2e4b; */
    opacity: 1;
    cursor: pointer;
    transition: opacity 0.3s ease-out, background-color 0.3s ease-out, color 0.3s ease-out;
}
.PaginationPrevious:not(.Disabled) > div:hover,
.PaginationNext:not(.Disabled) > div:hover {
    background-color: #191e3a;
    color: #bfc9d4;
}

.PaginationPrevious.Disabled > div,
.PaginationNext.Disabled > div {
    background: transparent;
    pointer-events: none;
    opacity: 0.3;
}
.PaginationPrevious svg,
.PaginationNext svg {
    width: 17px;
    /* color: #fff; */
}
.PaginationPrevious.Disabled svg,
.PaginationNext.Disabled svg {
    color: #888ea8;
}
.PaginationPrevious > div {
    margin-right: 5px;
}
.PaginationNext > div {
    margin-left: 5px;
}
.PageLink {
    margin-right: 5px;
    border-radius: 8px;
    color: #888ea8;
    height: 33px;
    width: 33px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba(0, 23, 55, 0.08);
}

.Disabled.PageLink {
    background: transparent;
    /* color: #6c757d; */
    pointer-events: none;
    border: none;
}
.PageLink:hover {
    background: #191e3a;
    color: #bfc9d4;
}
.PaginationPage.Active .PageLink {
    background-color: var(--action-color);
    border-color: #0d6efd;
    color: #fff;
}

@media (max-width: 700px) {
    .BottomBar {
        justify-content: center;
    }
}
